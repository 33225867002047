<template>
  <div>
    <el-form-item
      v-show="!!isSubFormChildWidget()"
      :label="i18nt('designer.setting.widgetColumnWidth')"
    >
      <el-input
        v-model="optionModel.columnWidth"
        type="text"
      />
    </el-form-item>
  </div>
</template>

<script>
import i18n from '@/utils/i18n'

/* eslint-disable */
export default {
  name: 'ColumnWidthEditor',
  mixins: [i18n],
  props: {
    designer: Object,
    selectedWidget: Object,
    optionModel: Object,
  },
  inject: ['isSubFormChildWidget'],
}
</script>

<style scoped>

</style>
