<template>
  <el-form-item :label="i18nt('designer.setting.multiple')">
    <el-switch
      v-model="optionModel.multiple"
      @change="onMultipleSelected"
    />
  </el-form-item>
</template>

<script>
import i18n from '@/utils/i18n'
import propertyMixin from '@/components/form-designer/setting-panel/property-editor/propertyMixin'

/* eslint-disable */
export default {
  name: 'MultipleEditor',
  mixins: [i18n, propertyMixin],
  props: {
    designer: Object,
    selectedWidget: Object,
    optionModel: Object,
  },
}
</script>

<style scoped>

</style>
