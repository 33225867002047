<template>
  <div
    v-if="$can('read', 'FormLiff')"
    style="height: inherit"
  >
    <b-overlay
      :show="showOverlay"
      rounded="sm"
    >
      <!-- Table Container Card -->
      <b-card
        no-body
      >

        <div class="m-2">

          <!-- Table Top -->
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pl-md-0 pr-md-0"
                >
                  <div class="d-flex align-items-center justify-content-end w-100">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                    <b-button
                      v-if="$can('create', 'FormLiff')"
                      variant="primary"
                      style="width: 100%; max-width: 200px;"
                      @click="addFormLiffDesign"
                    >
                      {{ `${$t('Design')} ${$t('Form Liff')}` }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <span class="text-muted mr-2">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totals"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>

        </div>
        <b-table
          ref="refTable"
          :items="getTable"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          hover
          :empty-text="$t('No matching records found')"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
          <!-- Column: ID -->
          <template #cell(id)="data">
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.6rem;">
                    <feather-icon
                      icon="TagIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">{{ `${data.item.name}` }}</span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    {{ $t('Create Date/Time') }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.create_date_show }}
                  </span>
                  <span style="margin-left: 1rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.create_time_show }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
            <div style="margin-top: 0.3rem;">
              <b-list-group>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    {{ $t('Update Date/Time') }}
                  </span>
                </b-list-group-item>
                <b-list-group-item
                  style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
                >
                  <span style="margin-right: 0.4rem;">
                    <feather-icon
                      icon="CalendarIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.update_date_show }}
                  </span>
                  <span style="margin-left: 1rem; margin-right: 0.4rem;">
                    <feather-icon
                      icon="ClockIcon"
                      size="14"
                    />
                  </span>
                  <span style="font-size: 14px;">
                    {{ data.item.update_time_show }}
                  </span>
                </b-list-group-item>
              </b-list-group>
            </div>
          </template>

          <!-- Column: form -->
          <template #cell(form)="data">
            <div
              style="margin-top: 0.3rem; max-width: 620px;"
            >
              <div
                class="form-render-wrapper"
                :class="[data.item.form.formConfig.layoutType === 'H5' ? 'h5-layout' :
                  (data.item.form.formConfig.layoutType === 'Pad' ? 'pad-layout' : '')]"
              >
                <VFormRender
                  ref="preForm"
                  :form-json="formJson(data.item.form)"
                  :preview-state="true"
                >
                  <!--
                  <div slot="testSlot">aaaa</div>
                  -->
                </VFormRender>
              </div>
            </div>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">

              <a
                class="action-icon"
                @click.stop="editFormLiffDesign(data)"
              >
                <feather-icon
                  v-if="$can('edit', 'FormLiff')"
                  :id="`formLiff-row-${data.item.id}-edit-icon`"
                  icon="EditIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('edit', 'FormLiff')"
                  :title="`${$t('Edit')} ${$t('FormLiff')}`"
                  :target="`formLiff-row-${data.item.id}-edit-icon`"
                  :delay="{ show: 3000, hide: 50 }"
                  placement="right"
                />
              </a>
              <a
                class="action-icon"
                @click.stop="deleteFormLiff(data)"
              >
                <feather-icon
                  v-if="$can('delete', 'FormLiff')"
                  :id="`formLiff-row-${data.item.id}-delete-icon`"
                  icon="TrashIcon"
                  variant="light-success"
                  size="16"
                  class="cursor-pointer mr-1"
                />
                <b-tooltip
                  v-if="$can('delete', 'FormLiff')"
                  :title="`${$t('Delete')} ${$t('FormLiff')}`"
                  :target="`formLiff-row-${data.item.id}-delete-icon`"
                  :delay="{ show: 3000, hide: 50 }"
                  placement="right"
                />
              </a>

            </div>
          </template>

        </b-table>
        <div class="mx-2 mb-2">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              sm="12"
              md="6"
            >
              <b-row>
                <b-col
                  cols="12"
                  sm="12"
                  md="3"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pr-0"
                >
                  <label>{{ $t('Entries') }}</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block ml-50 mr-1 w-100"
                  />
                </b-col>
                <b-col
                  cols="12"
                  sm="12"
                  md="9"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pl-md-0 pr-md-0"
                >
                  <div class="d-flex align-items-center justify-content-end w-100">
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      :placeholder="`${$t('Search')}...`"
                    />
                    <b-button
                      v-if="$can('create', 'FormLiff')"
                      variant="primary"
                      style="width: 100%; max-width: 200px;"
                      @click="addFormLiffDesign"
                    >
                      {{ `${$t('Design')} ${$t('Form Liff')}` }}
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="12"
              md="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <span class="text-muted mr-2">
                {{ $t('Showing {from} to {to} of {of} entries',
                      {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
                }}
              </span>
              <b-pagination
                v-model="currentPage"
                :total-rows="totals"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>

            </b-col>

          </b-row>
        </div>
      </b-card>

      <form-liff-design
        v-model="showFormLiffDesign"
        :form-liff-data="formLiffData"
        :type-form-liff-design="typeFormLiffDesign"
        @update-form-liff-design="updateFormLiffDesign"
        @discard-form-liff-design="discardFormLiffDesign"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable,
  BPagination, BTooltip, BListGroup, BListGroupItem,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import VFormRender from '@/components/form-render/index.vue'
import {
  deepClone,
} from '@/utils/util'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import FormLiffTable from './FormLiffTable'
import FormLiffStore from './FormLiffStore'
import FormLiffDesign from './FormLiffDesign.vue'

export default {
  components: {
    VFormRender,
    FormLiffDesign,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BTooltip,
    BListGroup,
    BListGroupItem,
    BButton,
    BOverlay,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      showOverlay: false,
      defaultData: {},
      formLiffData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      collapsedFilter: true,
      typeOptions: [],
      formData: {},
      formPropsData: {},
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
    formBindProps() {
      return {
        ...this.formPropsData,
        ...this.$attrs,
      }
    },
  },
  mounted() {
    this.locale = this.$i18n.locale
    this.getDefaultFormLiff()
  },
  methods: {
    getDefaultFormLiff() {
      this.showOverlay = true
      store
        .dispatch('form-liff-store/getDefaultFormLiff', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.formLiffData = this.defaultData
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    addFormLiffDesign() {
      this.showOverlay = true
      store
        .dispatch('form-liff-store/getDefaultFormLiff', { language: this.$i18n.locale, lineOaId: this.lineOaId })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.defaultData = response.data
          this.formLiffData = response.data
          this.typeFormLiffDesign = 'Add'
          this.showFormLiffDesign = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    editFormLiffDesign(data) {
      this.showOverlay = true
      store
        .dispatch('form-liff-store/getFormLiff', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.showOverlay = false
          this.formLiffData = response.data

          window.localStorage.setItem('widget__list__backup', JSON.stringify(this.formLiffData.form.widgetList))
          window.localStorage.setItem('form__config__backup', JSON.stringify(this.formLiffData.form.formConfig))

          this.typeFormLiffDesign = 'Edit'
          this.showFormLiffDesign = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
          this.showOverlay = false
        })
    },
    updateFormLiffDesign() {
      this.refetchData()
    },
    deleteFormLiff(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('form-liff-store/deleteFormLiff', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    discardFormLiffDesign() {
      this.refetchData()
    },
    formJson(form) {
      return {
        widgetList: deepClone(form.widgetList),
        formConfig: deepClone(form.formConfig),
      }
    },
  },
  setup() {
    const showFormLiffDesign = ref(false)
    const typeFormLiffDesign = ref('')

    if (!store.hasModule('form-liff-store')) store.registerModule('form-liff-store', FormLiffStore)
    onUnmounted(() => {
      if (store.hasModule('form-liff-store')) store.unregisterModule('form-liff-store')
    })

    const {
      getTable,
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      local,
      refTable,
      refetchData,
    } = FormLiffTable()

    return {
      showFormLiffDesign,
      typeFormLiffDesign,

      getTable,
      tableColumns,
      perPage,
      currentPage,
      totals,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      local,
      refTable,
      refetchData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import "~@core/scss/base/lineapi/formliff.scss";
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
.action-icon {
  :hover {
    color: #EB008B !important;
  }
}
</style>

<style lang="scss" scope>
@import '@core/scss/base/bootstrap-extended/include';
.media-heading {
  color: $body-color;
  margin-bottom: 0;
  line-height: 1.2;
}
</style>

<style lang="scss" scoped>
  div.toolbar-container {
    //min-width: 728px;  /* 解决工具按钮栏换行的问题 */
    /* 上一行css有问题，当窗口宽度不足时会把按钮挤出到右边的属性设置区，弃之！ */
  }

  .left-toolbar {
    float: left;
    font-size: 16px;
  }

  .right-toolbar {
    float: right;
    //width: 420px;
    text-align: right;
    overflow: hidden;

    .right-toolbar-con {
      text-align: left;
      width: 600px;
    }

    ::v-deep .el-button--text {
      font-size: 14px !important;
    }
  }

  .el-button i {
    margin-right: 3px;
  }

  .small-padding-dialog {
    ::v-deep .el-dialog__header {
      //padding-top: 3px;
      //padding-bottom: 3px;
      background: #f1f2f3;
    }

    ::v-deep .el-dialog__body {
      padding: 12px 15px 12px 15px;

      .el-alert.alert-padding {
        padding: 0 10px;
      }
    }

    ::v-deep .ace-container {
      border: 1px solid #DCDFE6;
    }
  }

  .dialog-title-light-bg {
    ::v-deep .el-dialog__header {
      background: #f1f2f3;
    }
  }

  .no-box-shadow {
    box-shadow: none;
  }

  .no-padding.el-tabs--border-card {
    ::v-deep .el-tabs__content {
      padding: 0;
    }
  }

  .form-render-wrapper {
    //height: calc(100vh - 142px);
    //all: revert !important; /* 防止表单继承el-dialog等外部样式，未生效，原因不明？？ */
  }

  .form-render-wrapper.h5-layout {
    margin: 0 auto;
    width: 420px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .form-render-wrapper.pad-layout {
    margin: 0 auto;
    width: 960px;
    border-radius: 15px;
    //border-width: 10px;
    box-shadow: 0 0 1px 10px #495060;
    height: calc(100vh - 142px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .node-tree-drawer ::v-deep {
    .el-drawer {
      padding: 10px;
      overflow: auto;
    }

    .el-drawer__header {
      margin-bottom: 12px;
      padding: 5px 5px 0;
    }

    .el-drawer__body {
      padding-left: 5px;
    }
  }

  /*.node-tree-scroll-bar {*/
  /*  height: 100%;*/
  /*  overflow: auto;*/
  /*}*/

  .node-tree ::v-deep {
    .el-tree > .el-tree-node:after {
      border-top: none;
    }
    .el-tree-node {
      position: relative;
      padding-left: 12px;
    }

    .el-tree-node__content {
      padding-left: 0 !important;
    }

    .el-tree-node__expand-icon.is-leaf{
      display: none;
    }
    .el-tree-node__children {
      padding-left: 12px;
      overflow: visible !important; /* 加入此行让el-tree宽度自动撑开，超出宽度el-draw自动出现水平滚动条！ */
    }

    .el-tree-node :last-child:before {
      height: 38px;
    }

    .el-tree > .el-tree-node:before {
      border-left: none;
    }

    .el-tree > .el-tree-node:after {
      border-top: none;
    }

    .el-tree-node:before {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:after {
      content: "";
      left: -4px;
      position: absolute;
      right: auto;
      border-width: 1px;
    }

    .el-tree-node:before {
      border-left: 1px dashed #4386c6;
      bottom: 0px;
      height: 100%;
      top: -10px;
      width: 1px;
    }

    .el-tree-node:after {
      border-top: 1px dashed #4386c6;
      height: 20px;
      top: 12px;
      width: 16px;
    }

    .el-tree-node.is-current > .el-tree-node__content {
      background: #c2d6ea !important;
    }

    .el-tree-node__expand-icon {
      margin-left: -3px;
      padding: 6px 6px 6px 0px;
      font-size: 16px;
    }

  }
</style>
