import { render, staticRenderFns } from "./sub-form-labelAlign-editor.vue?vue&type=template&id=301fe5d4&scoped=true&"
import script from "./sub-form-labelAlign-editor.vue?vue&type=script&lang=js&"
export * from "./sub-form-labelAlign-editor.vue?vue&type=script&lang=js&"
import style0 from "./sub-form-labelAlign-editor.vue?vue&type=style&index=0&id=301fe5d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "301fe5d4",
  null
  
)

export default component.exports