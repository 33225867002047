import { render, staticRenderFns } from "./appendButton-editor.vue?vue&type=template&id=c3cab43e&scoped=true&"
import script from "./appendButton-editor.vue?vue&type=script&lang=js&"
export * from "./appendButton-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3cab43e",
  null
  
)

export default component.exports