<template>
  <container-item-wrapper :widget="widget">

    <el-row
      v-show="!widget.options.hidden"
      :key="widget.id"
      :ref="widget.id"
      :gutter="widget.options.gutter"
      class="grid-container"
      :class="[customClass]"
    >
      <template>
        <grid-col-item
          v-for="(colWidget, colIdx) in widget.cols"
          :key="colIdx"
          :widget="colWidget"
          :parent-list="widget.cols"
          :index-of-parent-list="colIdx"
          :parent-widget="widget"
          :col-height="widget.options.colHeight"
        >
          <!-- 递归传递插槽！！！ -->
          <template
            v-for="slot in Object.keys($scopedSlots)"
            v-slot:[slot]="scope"
          >
            <slot
              :name="slot"
              v-bind="scope"
            />
          </template>
        </grid-col-item>
      </template>
    </el-row>

  </container-item-wrapper>
</template>

<script>
import emitter from '@/utils/emitter'
import i18n from '../../../utils/i18n'
import refMixin from '../refMixin'
import ContainerItemWrapper from './container-item-wrapper.vue'
import GridColItem from './grid-col-item.vue'
import containerItemMixin from './containerItemMixin'

/* eslint-disable */
export default {
  name: 'VfGridItem', // grid-item跟VueGridLayout全局注册组件重名，故特殊处理！！
  componentName: 'ContainerItem',
  components: {
    ContainerItemWrapper,
    GridColItem,
  },
  mixins: [emitter, i18n, refMixin, containerItemMixin],
  props: {
    widget: Object,
  },
  inject: ['refList', 'sfRefList', 'globalModel'],
  created() {
    this.initRefList()
  },
  mounted() {

  },
  beforeDestroy() {
    this.unregisterFromRefList()
  },
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>
