import { render, staticRenderFns } from "./requiredHint-editor.vue?vue&type=template&id=f8c40e90&scoped=true&"
import script from "./requiredHint-editor.vue?vue&type=script&lang=js&"
export * from "./requiredHint-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8c40e90",
  null
  
)

export default component.exports