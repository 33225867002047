import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// FormLiff
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { t, t2 } from '@/@core/libs/i18n/utils'

export default function FormLiffTable() {
  // Use toast
  const toast = useToast()

  const refTable = ref(null)
  const lineOa = computed(() => store.state.lineapi.lineOa)

  const msgFormLiffList = {
    errorfetch: t2('Error fetching {module} list', { module: t('FormLiff') }),
    id: `${t('Name')} » ${t('Create Date/Time')} » ${t('Update Date/Time')} » ${t('ID')}`,
    form: `${t('Form Liff')}`,
    actions: t('Actions'),
  }

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true, label: msgFormLiffList.id },
    { key: 'form', sortable: false, label: msgFormLiffList.form },
    { key: 'actions', label: msgFormLiffList.actions },
  ]

  const filterList = JSON.parse(localStorage.getItem('filterFormLiffList'))

  const perPage = ref((filterList != null && filterList.perPage != null) ? filterList.perPage : 10)
  const totals = ref(0)
  // const currentPage = ref((filterList != null && filterList.page != null) ? filterList.page : 1)
  const currentPage = ref(1)
  const perPageOptions = [2, 5, 10, 25, 50, 100]
  const searchQuery = ref((filterList != null && filterList.q != null) ? filterList.q : '')
  const sortBy = ref((filterList != null && filterList.sortBy != null) ? filterList.sortBy : 'id')
  const isSortDirDesc = ref((filterList != null && filterList.sortDesc != null) ? filterList.sortDesc : true)
  const locale = ref('th')

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totals.value,
    }
  })

  const refetchData = () => {
    if (refTable.value !== null) {
      refTable.value.refresh()
    }
  }

  watch([currentPage, perPage, searchQuery, locale], () => {
    refetchData()
  })

  const getTable = (ctx, callback) => {
    const { id } = JSON.parse(lineOa.value)
    const params = {
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      language: locale.value,
      lineOaId: id,
    }
    store
      .dispatch('form-liff-store/getListFormLiff', params)
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totals.value = total
        localStorage.setItem('filterFormLiffList', JSON.stringify(params))
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: msgFormLiffList.errorfetchFormLiff,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
      })
  }

  return {
    getTable,
    tableColumns,
    perPage,
    currentPage,
    totals,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    locale,
    refTable,
    filterList,

    refetchData,
  }
}
