import { render, staticRenderFns } from "./onAppendButtonClick-editor.vue?vue&type=template&id=7b247193&scoped=true&"
import script from "./onAppendButtonClick-editor.vue?vue&type=script&lang=js&"
export * from "./onAppendButtonClick-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b247193",
  null
  
)

export default component.exports