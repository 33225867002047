<template>
  <el-dialog
    v-if="showFormLiffDesign"
    :visible="showFormLiffDesign"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    center
    :destroy-on-close="true"
    :append-to-body="true"
    class="design-padding-dialog"
    width="100%"
    :fullscreen="true"
  >
    <div class="el__header">
      <div class="_title">
        <b-row>
          <b-col cols="8">
            <div>
              {{ `${$t('Design')} ${$t('Form Liff')}` }}
            </div>
          </b-col>
          <b-col cols="4">
            <div>
              <b-form-input
                ref="refName"
                v-model="formLiffValue.name"
                :disabled="busy"
              />
              <small
                v-if="isErrorName"
                class="text-danger"
              >{{ msgErrorName }}</small>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="_close">
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          size="18"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </div>
    <div style="background: #f1f1f1; height: 1px; margin-bottom: 0rem;" />
    <div>
      <VFormDesigner
        ref="vfDesignerRef"
        :designer-config="designerConfig"
      >
        <template #customToolButtons>
          <el-button
            type="text"
            @click="saveFormJson"
          >
            {{ `${$t('Add')}/${$t('Save')}` }}
          </el-button>
        </template>
      </VFormDesigner>
    </div>

    <b-overlay
      :show="busy"
      no-wrap
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </el-dialog>
</template>

<script>
import {
  BButton,
  BOverlay,
  BProgress,
  BCardText,
  BFormInput,
  BCol,
  BRow,
} from 'bootstrap-vue'
import {
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VFormDesigner from '@/components/form-designer/index.vue'
import formLiffStore from './FormLiffStore'

export default {
  name: 'App',
  components: {
    VFormDesigner,
    BButton,
    BOverlay,
    BProgress,
    BCardText,
    BFormInput,
    BCol,
    BRow,
    FeatherIcon,
  },
  model: {
    prop: 'showFormLiffDesign',
    event: 'update:show-form-liff-design',
  },
  props: {
    showFormLiffDesign: {
      type: Boolean,
      required: true,
    },
    typeFormLiffDesign: {
      type: String,
      default: 'Add',
    },
    formLiffData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isErrorName: false,
      msgErrorName: '',
      designerConfig: {
        resetFormJson: false,
        toolbarMaxWidth: 490,
      },
      formLiffValue: {
        id: 0,
        name: '',
        form: '',
        line_oa_id: 0,
      },
    }
  },
  computed: {
    lineOa() {
      const value = store.state.lineapi.lineOa
      if (value === '' || value === undefined) {
        return ''
      }
      return JSON.parse(value)
    },
    lineOaId() {
      const value = this.lineOa.id
      if (value === '' || value === undefined) {
        return ''
      }
      return value
    },
  },
  watch: {
    formLiffData(val) {
      console.log(val)
      this.formLiffValue.name = val.name
      this.formLiffValue.id = val.id
    },
  },
  methods: {
    onDiscard() {
      this.$emit('discard-form-liff-design')
      this.$emit('update:show-form-liff-design', false)
    },
    saveFormJson() {
      if (this.formLiffValue.name === '') {
        this.isErrorName = true
        this.msgErrorName = this.$i18n.t('Please input name form')
        this.$refs.refName.focus()
      } else {
        this.isErrorName = false
        this.msgErrorName = ''
        // console.log(this.$refs.vfDesignerRef.getFormJson())
        this.formLiffValue.line_oa_id = this.lineOaId
        this.formLiffValue.form = this.$refs.vfDesignerRef.getFormJson()
        this.processing = false
        this.busy = true
      }
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeFormLiffDesign === 'Add') {
        store.dispatch('form-liff-store/addFormLiff', this.formLiffValue)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-form-liff-design')
            this.$emit('update:show-form-liff-design', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeFormLiffDesign === 'Edit') {
        store.dispatch('form-liff-store/editFormLiff', {
          id: this.formLiffValue.id,
          data: this.formLiffValue,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('update-form-liff-design')
            this.$emit('update:show-form-liff-design', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
  },
  setup() {
    if (!store.hasModule('form-liff-store')) store.registerModule('form-liff-store', formLiffStore)
    onUnmounted(() => {
      if (store.hasModule('form-liff-store')) store.unregisterModule('form-liff-store')
    })

    return {}
  },
}
</script>

<style lang="scss" scoped>
.el__header {
  display: flex;
  ._title {
    width: 100%;
    margin: -0.4rem 0rem 0rem 0rem;
    padding: 0rem 1rem 0.3rem 1rem;
    font-size: 16px;
  }
  ._close {
    margin: -0.4rem 0rem 0rem 0rem;
    padding: 0rem 1rem 0.3rem 1rem;
    text-align: end;
  }
}
.design-padding-dialog {
  // ::v-deep .el-dialog__title{
  //   font-size: 16px;
  // }
  ::v-deep .el-dialog__header {
    padding: 0rem 0rem 0rem 0rem;
    background: #f1f2f3;
    display: none;
  }
  // ::v-deep .el-dialog__headerbtn {
  //   margin-top: -20px;
  // }

  ::v-deep .el-dialog__body {
    padding: 12px 0px 12px 0px;
  }
}
</style>
