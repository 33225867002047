import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getListFormLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_form_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFormLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_form_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFormLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_form_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editFormLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_form_liff', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFormLiff(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/lineapi/delete_form_liff/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultFormLiff(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_form_liff', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListFormLiffSelect(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_form_liff_select', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
