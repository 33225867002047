<template>
  <el-form-item :label="i18nt('designer.setting.allowHalf')">
    <el-switch v-model="optionModel.allowHalf" />
  </el-form-item>
</template>

<script>
import i18n from '@/utils/i18n'

/* eslint-disable */
export default {
  name: 'AllowHalfEditor',
  mixins: [i18n],
  props: {
    designer: Object,
    selectedWidget: Object,
    optionModel: Object,
  },
}
</script>

<style scoped>

</style>
