import { render, staticRenderFns } from "./max-editor.vue?vue&type=template&id=3d8a5730&scoped=true&"
import script from "./max-editor.vue?vue&type=script&lang=js&"
export * from "./max-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d8a5730",
  null
  
)

export default component.exports