import { render, staticRenderFns } from "./required-editor.vue?vue&type=template&id=766c8840&scoped=true&"
import script from "./required-editor.vue?vue&type=script&lang=js&"
export * from "./required-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "766c8840",
  null
  
)

export default component.exports