import { render, staticRenderFns } from "./hidden-editor.vue?vue&type=template&id=edab5200&scoped=true&"
import script from "./hidden-editor.vue?vue&type=script&lang=js&"
export * from "./hidden-editor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "edab5200",
  null
  
)

export default component.exports