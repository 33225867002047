<template>
  <div style="display: none" />
</template>

<script>
/* eslint-disable */
export default {
  name: 'CascaderDefaultValueEditor',
  props: {
    designer: Object,
    selectedWidget: Object,
    optionModel: Object,
  },
}
</script>

<style scoped>

</style>
