<template>
  <el-container class="main-container full-height">
    <!-- <el-header class="main-header">
      <div class="float-left main-title">
        <img
          src="../../assets/vform-logo.png"
          @click="openHome"
        >
        <span class="bold">VForm</span> {{ i18nt('application.productTitle') }} <span class="version-span">Ver {{ vFormVersion }}</span>
      </div>
      <div class="float-right external-link">
        <el-dropdown
          v-if="showLink('languageMenu')"
          :hide-timeout="2000"
          @command="handleLanguageChanged"
        >
          <span class="el-dropdown-link">{{ curLangName }}<i class="el-icon-arrow-down el-icon--right" /></span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="th-TH">
              {{ i18nt('application.th-TH') }}
            </el-dropdown-item>
            <el-dropdown-item command="en-US">
              {{ i18nt('application.en-US') }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <a
          v-if="showLink('externalLink')"
          href="javascript:void(0)"
          target="_blank"
          @click="(ev) => openUrl(ev, gitUrl)"
        ><svg-icon icon-class="github" />{{ i18nt('application.github') }}</a>
        <a
          v-if="showLink('externalLink')"
          href="javascript:void(0)"
          target="_blank"
          @click="(ev) => openUrl(ev, docUrl)"
        ><svg-icon icon-class="document" />{{ i18nt('application.document') }}</a>
        <a
          v-if="showLink('externalLink')"
          href="javascript:void(0)"
          target="_blank"
          @click="(ev) => openUrl(ev, chatUrl)"
        >{{ i18nt('application.qqGroup') }}</a>
        <a
          v-if="showLink('externalLink')"
          href="javascript:void(0)"
          target="_blank"
          @click="(ev) => openUrl(ev, subScribeUrl)"
        >
          {{ i18nt('application.subscription') }}<i class="el-icon-top-right" /></a>
      </div>
    </el-header> -->

    <el-container>
      <el-aside class="side-panel">
        <widget-panel :designer="designer" />
      </el-aside>

      <el-container class="center-layout-container">
        <el-header class="toolbar-header">
          <toolbar-panel
            ref="toolbarRef"
            :designer="designer"
          >
            <template
              v-for="(idx, slotName) in $slots"
              #[slotName]
            >
              <slot :name="slotName" />
            </template>
          </toolbar-panel>
        </el-header>
        <el-main class="form-widget-main">
          <el-scrollbar
            class="container-scroll-bar"
            :style="{height: scrollerHeight}"
          >
            <v-form-widget
              ref="formRef"
              :designer="designer"
              :form-config="designer.formConfig"
            />
          </el-scrollbar>
        </el-main>
      </el-container>

      <el-aside>
        <setting-panel
          :designer="designer"
          :selected-widget="designer.selectedWidget"
          :form-config="designer.formConfig"
        />
      </el-aside>
    </el-container>

  </el-container>
</template>

<script>
import { createDesigner } from '@/components/form-designer/designer'
import {
  addWindowResizeHandler,
  deepClone,
  getAllContainerWidgets,
  getAllFieldWidgets,
  getQueryParam,
  traverseAllWidgets,
} from '@/utils/util'
import {
  MOCK_CASE_URL,
  VARIANT_FORM_VERSION,
} from '@/utils/config'
import i18n,
{
  changeLocale,
} from '@/utils/i18n'
import axios from 'axios'
// import SvgIcon from '@/components/svg-icon/index.vue'
import VFormWidget from './form-widget/index.vue'
import SettingPanel from './setting-panel/index.vue'
import ToolbarPanel from './toolbar-panel/index.vue'
import WidgetPanel from './widget-panel/index.vue'

export default {
  name: 'VFormDesigner',
  componentName: 'VFormDesigner',
  components: {
    WidgetPanel,
    ToolbarPanel,
    SettingPanel,
    VFormWidget,
    // SvgIcon,
  },
  mixins: [i18n],
  props: {
    fieldListApi: {
      type: Object,
      default: null,
    },

    bannedWidgets: {
      type: Array,
      default: () => [],
    },

    designerConfig: {
      type: Object,
      default: () => ({
        languageMenu: true,
        externalLink: true,
        formTemplates: true,
        eventCollapse: true,
        widgetNameReadonly: false,

        clearDesignerButton: true,
        previewFormButton: true,
        importJsonButton: true,
        exportJsonButton: true,
        exportCodeButton: true,
        generateSFCButton: true,
        toolbarMaxWidth: 420,
        toolbarMinWidth: 340,

        presetCssCode: '',

        resetFormJson: false,
      }),
    },

  },
  data() {
    return {
      vFormVersion: VARIANT_FORM_VERSION,
      curLangName: '',

      vsCodeFlag: false,
      caseName: '',

      docUrl: 'https://www.vform666.com/document.html',
      gitUrl: 'https://github.com/vform666/variant-form',
      chatUrl: 'https://www.vform666.com/pages/chat-group/',
      subScribeUrl: 'https://www.vform666.com/pages/pro/',

      scrollerHeight: 0,

      designer: createDesigner(this),

      fieldList: [],
    }
  },
  provide() {
    return {
      serverFieldList: this.fieldList,
      getDesignerConfig: () => this.designerConfig,
      getBannedWidgets: () => this.bannedWidgets,
    }
  },
  created() {
    this.vsCodeFlag = getQueryParam('vscode') === 1
    this.caseName = getQueryParam('case')
  },
  mounted() {
    this.initLocale()

    this.scrollerHeight = `${window.innerHeight - 56 - 36}px`
    addWindowResizeHandler(() => {
      this.$nextTick(() => {
        this.scrollerHeight = `${window.innerHeight - 56 - 36}px`
      })
    })

    this.loadCase()

    this.loadFieldListFromServer()
  },
  methods: {
    // showLink(configName) {
    //   if (this.designerConfig[configName] === undefined) {
    //     return true
    //   }

    //   return !!this.designerConfig[configName]
    // },

    // openHome() {
    //   if (this.vsCodeFlag) {
    //     const msgObj = {
    //       cmd: 'openUrl',
    //       data: {
    //         url: 'https://www.vform666.com/',
    //       },
    //     }
    //     window.parent.postMessage(msgObj, '*')
    //   }
    // },

    // openUrl(event, url) {
    //   if (this.vsCodeFlag) {
    //     const msgObj = {
    //       cmd: 'openUrl',
    //       data: {
    //         url,
    //       },
    //     }
    //     window.parent.postMessage(msgObj, '*')
    //   } else {
    //     const aDom = event.currentTarget
    //     aDom.href = url
    //     // window.open(url, '_blank') //直接打开新窗口，会被浏览器拦截
    //   }
    // },

    loadCase() {
      if (!this.caseName) {
        return
      }

      axios.get(`${MOCK_CASE_URL + this.caseName}.txt`).then(res => {
        if (res.data.code) {
          this.$message.error(this.i18nt('designer.hint.sampleLoadedFail'))
          return
        }

        this.setFormJson(res.data)
        this.$message.success(this.i18nt('designer.hint.sampleLoadedSuccess'))
      }).catch(error => {
        this.$message.error(`${this.i18nt('designer.hint.sampleLoadedFail')}:${error}`)
      })
    },

    initLocale() {
      let curLocale = localStorage.getItem('liff_form_locale')
      if (this.vsCodeFlag) {
        curLocale = curLocale || 'th-TH'
      } else {
        curLocale = curLocale || 'th-TH'
      }
      this.curLangName = this.i18nt(`application.${curLocale}`)
      this.changeLanguage(curLocale)
    },

    loadFieldListFromServer() {
      if (!this.fieldListApi) {
        return
      }

      const headers = this.fieldListApi.headers || {}
      axios.get(this.fieldListApi.URL, { headers }).then(res => {
        const labelKey = this.fieldListApi.labelKey || 'label'
        const nameKey = this.fieldListApi.nameKey || 'name'

        this.fieldList.splice(0, this.fieldList.length) // 清空已有
        res.data.forEach(fieldItem => {
          this.fieldList.push({
            label: fieldItem[labelKey],
            name: fieldItem[nameKey],
          })
        })
      }).catch(error => {
        this.$message.error(error)
      })
    },

    handleLanguageChanged(command) {
      this.changeLanguage(command)
      this.curLangName = this.i18nt(`application.${command}`)
    },

    changeLanguage(langName) {
      changeLocale(langName)
    },

    setFormJson(formJson) {
      let modifiedFlag = false
      if (formJson) {
        if (typeof formJson === 'string') {
          modifiedFlag = this.designer.loadFormJson(JSON.parse(formJson))
        } else if (formJson.constructor === Object) {
          modifiedFlag = this.designer.loadFormJson(formJson)
        }

        if (modifiedFlag) {
          this.designer.emitHistoryChange()
        }
      }
    },

    getFormJson() {
      return {
        widgetList: deepClone(this.designer.widgetList),
        formConfig: deepClone(this.designer.formConfig),
      }
    },

    clearDesigner() {
      this.$refs.toolbarRef.clearFormWidget()
    },

    /**
       * 刷新表单设计器
       */
    refreshDesigner() {
      // this.designer.loadFormJson( this.getFormJson() )  //只有第一次调用生效？？

      const fJson = this.getFormJson()
      this.designer.clearDesigner(true) // 不触发历史记录变更
      this.designer.loadFormJson(fJson)
    },

    /**
       * 预览表单
       */
    previewForm() {
      this.$refs.toolbarRef.previewForm()
    },

    /**
       * 导入表单JSON
       */
    importJson() {
      this.$refs.toolbarRef.importJson()
    },

    /**
       * 导出表单JSON
       */
    exportJson() {
      this.$refs.toolbarRef.exportJson()
    },

    /**
       * 导出Vue/HTML代码
       */
    exportCode() {
      this.$refs.toolbarRef.exportCode()
    },

    /**
       * 生成SFC代码
       */
    generateSFC() {
      this.$refs.toolbarRef.generateSFC()
    },

    /**
       * 获取所有字段组件
       * @returns {*[]}
       */
    getFieldWidgets(widgetList = null) {
      return widgetList ? getAllFieldWidgets(widgetList) : getAllFieldWidgets(this.designer.widgetList)
    },

    /**
       * 获取所有容器组件
       * @returns {*[]}
       */
    getContainerWidgets(widgetList = null) {
      return widgetList ? getAllContainerWidgets(widgetList) : getAllContainerWidgets(this.designer.widgetList)
    },

    /**
       * 升级表单json，以补充最新的组件属性
       * @param formJson
       */
    upgradeFormJson(formJson) {
      if (!formJson.widgetList || !formJson.formConfig) {
        this.$message.error('Invalid form json!')
        return
      }

      traverseAllWidgets(formJson.widgetList, w => {
        this.designer.upgradeWidgetConfig(w)
      })
      this.designer.upgradeFormConfig(formJson.formConfig)

      // eslint-disable-next-line consistent-return
      return formJson
    },

    getWidgetRef(widgetName, showError = false) {
      return this.$refs.formRef.getWidgetRef(widgetName, showError)
    },

    getSelectedWidgetRef() {
      return this.$refs.formRef.getSelectedWidgetRef()
    },
  },
}
</script>

<style lang="scss" scoped>
  .el-container.main-container {
    background: #fff;

    ::v-deep aside {  /* 防止aside样式被外部样式覆盖！！ */
      margin: 0;
      padding: 0;
      background: inherit;
    }
  }

  .el-container.full-height {
    height: 100%;
    overflow-y: hidden;
  }

  .el-container.center-layout-container {
    min-width: 680px;
    border-left: 2px dotted #EBEEF5;
    border-right: 2px dotted #EBEEF5;
  }

  .el-header.main-header {
    border-bottom: 2px dotted #EBEEF5;
    height: 48px !important;
    line-height: 48px !important;
    min-width: 800px;
  }

  div.main-title {
    font-size: 18px;
    color: #242424;
    display: flex;
    align-items: center;
    justify-items: center;

    img {
      cursor: pointer;
      width: 36px;
      height: 36px;
    }

    span.bold {
      font-size: 20px;
      font-weight: bold;
      margin: 0 6px 0 6px;
    }

    span.version-span {
      font-size: 14px;
      color: #101F1C;
      margin-left: 6px;
    }
  }

  .float-left {
    float: left;
  }

  .float-right {
    float: right;
  }

  .el-dropdown-link {
    margin-right: 12px;
    cursor: pointer;
  }

  div.external-link a {
    font-size: 13px;
    text-decoration: none;
    margin-right: 10px;
    color: #606266;
  }

  .el-header.toolbar-header {
    font-size: 14px;
    border-bottom: 1px dotted #CCCCCC;
    height: 42px !important;
    //line-height: 42px !important;
  }

  .el-aside.side-panel {
    width: 260px !important;
    overflow-y: hidden;
  }

  .el-main.form-widget-main {
    padding: 0;

    position: relative;
    overflow-x: hidden;
  }

  .container-scroll-bar {
    ::v-deep .el-scrollbar__wrap, ::v-deep .el-scrollbar__view {
      overflow-x: hidden;
    }
  }
</style>
